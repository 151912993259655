import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import _ from "lodash";

const Heatmap = ({ mafData }) => {
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [originalDataset, setOriginalDataset] = useState(null);
  const [isolated, setIsolated] = useState(false);
  const [fields, setFields] = useState([]);
  const heatMapRef = useRef(null);

  useEffect(() => {
    if (!mafData) {
      console.error("MAF verisi sağlanmadı");
      return;
    }

    try {
      const reader = new window.morpheus.MafFileReader();

      reader.read(mafData, (err, dataset) => {
        if (err) {
          console.error("MAF verisi okunurken hata:", err);
          return;
        }

        if (heatMapRef.current) {
          return; // Heatmap'in yeniden başlatılmasını önle
        }

        setOriginalDataset(dataset);
        const heatMap = new window.morpheus.HeatMap({
          el: document.getElementById("vis"),
          dataset: dataset,
          name: "Morpheus App",

          colorScheme: {
            valueToColorScheme: {
              null: {
                fractions: [
                  0, 0.14285714285714285, 0.2857142857142857,
                  0.42857142857142855, 0.5714285714285714, 0.7142857142857143,
                  0.8571428571428571, 1,
                ],
                colors: [
                  "#ffffff",
                  "#66A463",
                  "#54C1C4",
                  "#ABC5BC",
                  "#2C91BF",
                  "#C7A2CD",
                  "#F48478",
                  "#C8292D",
                ],
                min: 0,
                max: 7,
                missingColor: "#c0c0c0",
                scalingMode: 1,
                stepped: true,
                names: [
                  "",
                  "Synonymous",
                  "In Frame Indel",
                  "Other Non-Synonymous",
                  "Missense",
                  "Splice Site",
                  "Frame Shift",
                  "Nonsense",
                ],
              },
            },
          },

          columns: [
            {
              squished: false,
              inlineTooltip: false,
              tooltip: true,
              highlightMatchingValues: false,
              colorBarSize: 12,
              stackedBar: false,
              display: ["text"],
              selectionColor: "rgb(182,213,253)",
              colorByField: null,
              fontField: null,
              barColor: "#bdbdbd",
              barSize: 40,
              autoscaleAlways: false,
              minMaxReversed: false,
              field: "id",
              maxTextWidth: 28.66666603088379,
              size: {},
              showInTooltip: true,
            },
            {
              squished: false,
              inlineTooltip: true,
              tooltip: true,
              highlightMatchingValues: false,
              colorBarSize: 12,
              stackedBar: true,
              display: ["bar"],
              selectionColor: "rgb(182,213,253)",
              colorByField: null,
              fontField: null,
              barColor: "#bdbdbd",
              barSize: 40,
              autoscaleAlways: false,
              minMaxReversed: false,
              field: "mutation_summary",
              min: 0,
              max: 25,
              mid: 0,
              size: {},
              showInTooltip: true,
            },
          ],
        });
        heatMapRef.current = heatMap;
        console.log(window.morpheus);

        window.morpheus.FilterUI = function (project, isColumns) {
          var _this = this;
          this.project = project;
          this.isColumns = isColumns;
          var $div = $('<div style="min-width:180px;"></div>');
          this.$div = $div;
          $div.append(this.addBase());
          var $filters = $('<div data-name="filter-wrapper"></div>');
          this.$filters = $filters;
          var combinedFilter = isColumns
            ? project.getColumnFilter()
            : project.getRowFilter();
          $div.append($filters);
          var $filterMode = $div.find("[name=filterMode]");
          $filterMode.prop("checked", combinedFilter.isAnd());
          $filterMode.on("change", function (e) {
            var isAndFilter = $filterMode.prop("checked");
            combinedFilter.setAnd(isAndFilter);
            isColumns
              ? _this.project.setColumnFilter(
                  _this.project.getColumnFilter(),
                  true
                )
              : _this.project.setRowFilter(_this.project.getRowFilter(), true);
            e.preventDefault();
          });
          $div.on("submit", "form", function (e) {
            e.preventDefault();
          });
          $div.on("click", "[data-name=add]", function (e) {
            var newFilter = new window.morpheus.AlwaysTrueFilter(isColumns);
            combinedFilter.insert(0, newFilter);
            var $add = $(_this.add(newFilter));
            $add.data("filter", newFilter);
            $add.prependTo($filters);
            e.preventDefault();
          });
          $div.on("click", "[data-name=delete]", function (e) {
            var $this = $(this);
            var $entry = $this.closest(".morpheus-entry");
            var filter = $entry.data("filter");
            var index = combinedFilter.getFilters().indexOf(filter);
            if (index === -1) {
              throw "Not found";
            }
            combinedFilter.remove(index);
            $entry.remove();
            isColumns
              ? _this.project.setColumnFilter(
                  _this.project.getColumnFilter(),
                  true
                )
              : _this.project.setRowFilter(_this.project.getRowFilter(), true);
            e.preventDefault();
          });

          $div.on("change", "[name=by]", function (e) {
            var $this = $(this);
            var fieldName = $this.val();
            var $entry = $this.closest(".morpheus-entry");
            var filter = $entry.data("filter");
            var index = combinedFilter.getFilters().indexOf(filter);
            if (index === -1) {
              throw "Not found";
            }
            _this.createFilter({
              fieldName: fieldName,
              $entry: $entry,
              index: index,
            });
            isColumns
              ? _this.project.setColumnFilter(
                  _this.project.getColumnFilter(),
                  true
                )
              : _this.project.setRowFilter(_this.project.getRowFilter(), true);
          });
          // $div.on('change', '[name=variant-filter]', function (e) {
          //   console.log('salam')
          //   var $this = $(this)
          //   var fieldName = $this.val()
          //   var $entry = $this.closest('.morpheus-entry')
          //   var filter = $entry.data('filter')
          //   console.log(fieldName, $entry, filter, combinedFilter)
          //   var index = combinedFilter.getFilters().indexOf(filter)
          // if (index === -1) {
          //   throw "Not found";
          // }
          // _this.createFilter({
          //   fieldName: fieldName,
          //   $entry: $entry,
          //   index: index,
          // });
          // isColumns
          //   ? _this.project.setColumnFilter(
          //       _this.project.getColumnFilter(),
          //       true
          //     )
          //   : _this.project.setRowFilter(_this.project.getRowFilter(), true);
          // })
          $div.on("change", "[name=by]", function (e) {
            var $this = $(this);
            var fieldName = $this.val();
            var $entry = $this.closest(".morpheus-entry");
            var filter = $entry.data("filter");
            var index = combinedFilter.getFilters().indexOf(filter);
            if (index === -1) {
              throw "Not found";
            }
            _this.createFilter({
              fieldName: fieldName,
              $entry: $entry,
              index: index,
            });
            isColumns
              ? _this.project.setColumnFilter(
                  _this.project.getColumnFilter(),
                  true
                )
              : _this.project.setRowFilter(_this.project.getRowFilter(), true);
          });

          var filters = combinedFilter.getFilters
            ? combinedFilter.getFilters()
            : [];
          for (var i = 0; i < filters.length; i++) {
            this.createFilter({
              filter: filters[i],
            });
          }
          if (combinedFilter.on) {
            combinedFilter.on("add", function (e) {
              _this.createFilter({
                filter: e.filter,
              });
            });
            combinedFilter.on("remove", function (e) {
              var $row = $div.find(".morpheus-entry")[1 + e.index].remove();
            });
            combinedFilter.on("and", function (e) {
              $filterMode.prop("checked", e.source.isAnd());
            });
          }
        };

        window.morpheus.FilterUI.rangeFilter = function (
          project,
          name,
          isColumns,
          $ui,
          filter
        ) {
          $ui.empty();
          var html = [];
          html.push("<label>Range of values</label><br />");
          html.push(
            '<label>>= </label> <input style="max-width:200px;" class="form-control input-sm" name="min" type="text" />'
          );
          html.push(
            '<label> and <= </label> <input style="max-width:200px;" class="form-control input-sm" name="max" type="text" />'
          );
          html.push(
            '<br /><a data-name="switch" href="#">Switch to top filter</a>'
          );
          var $form = $(html.join(""));
          $form.appendTo($ui);
          $ui.find("[data-name=switch]").on("click", function (e) {
            e.preventDefault();
            var newFilter = window.morpheus.FilterUI.topFilter(
              project,
              name,
              isColumns,
              $ui
            );
            var index = -1;
            var filters = isColumns
              ? project.getColumnFilter().getFilters()
              : project.getRowFilter().getFilters();
            for (var i = 0; i < filters.length; i++) {
              if (filters[i] === filter) {
                index = i;
                break;
              }
            }
            if (index === -1) {
              throw new Error("Filter not found.");
            }
            (isColumns
              ? project.getColumnFilter()
              : project.getRowFilter()
            ).set(index, newFilter);
            isColumns
              ? project.setColumnFilter(project.getColumnFilter(), true)
              : project.setRowFilter(project.getRowFilter(), true);
          });
          var $min = $ui.find("[name=min]");
          var $max = $ui.find("[name=max]");
          if (!filter) {
            filter = new window.morpheus.RangeFilter(
              -Number.MAX_VALUE,
              Number.MAX_VALUE,
              name,
              isColumns
            );
          } else {
            $min.val(filter.min);
            $max.val(filter.max);
          }

          $min.on(
            "keyup",
            _.debounce(function (e) {
              filter.setMin(parseFloat($.trim($(this).val())));
              isColumns
                ? project.setColumnFilter(project.getColumnFilter(), true)
                : project.setRowFilter(project.getRowFilter(), true);
            }, 500)
          );
          $max.on(
            "keyup",
            _.debounce(function (e) {
              filter.setMax(parseFloat($.trim($(this).val())));
              isColumns
                ? project.setColumnFilter(project.getColumnFilter(), true)
                : project.setRowFilter(project.getRowFilter(), true);
            }, 500)
          );

          return filter;
        };

        window.morpheus.FilterUI.topFilter = function (
          project,
          name,
          isColumns,
          $ui,
          filter
        ) {
          $ui.empty();
          var html = [];
          html.push("<label>Top</label><br />");
          html.push(
            '<select style="width:auto;" class="form-control input-sm" name="direction"><option value="Top">Top</option><option value="Bottom">Bottom</option><option value="TopBottom">Top/Bottom</option></select>'
          );
          html.push(
            ' <label>N </label> <input style="max-width:200px;" class="form-control input-sm" name="n" type="text" />'
          );
          html.push(
            '<br /><a data-name="switch" href="#">Switch to range filter</a>'
          );
          var $form = $(html.join(""));
          $form.appendTo($ui);
          var $n = $ui.find("[name=n]");
          var $direction = $ui.find("[name=direction]");
          $ui.find("[data-name=switch]").on("click", function (e) {
            e.preventDefault();
            var newFilter = window.morpheus.FilterUI.rangeFilter(
              project,
              name,
              isColumns,
              $ui
            );
            var index = -1;
            var filters = isColumns
              ? project.getColumnFilter().getFilters()
              : project.getRowFilter().getFilters();
            for (var i = 0; i < filters.length; i++) {
              if (filters[i] === filter) {
                index = i;
                break;
              }
            }
            if (index === -1) {
              throw new Error("Filter not found.");
            }
            (isColumns
              ? project.getColumnFilter()
              : project.getRowFilter()
            ).set(index, newFilter);
            isColumns
              ? project.setColumnFilter(project.getColumnFilter(), true)
              : project.setRowFilter(project.getRowFilter(), true);
          });
          if (!filter) {
            filter = new window.morpheus.TopNFilter(
              NaN,
              window.morpheus.TopNFilter.TOP,
              name,
              isColumns
            );
          } else {
            var dirVal;
            if (filter.direction === window.morpheus.TopNFilter.TOP) {
              dirVal = "Top";
            } else if (filter.direction === window.morpheus.TopNFilter.BOTTOM) {
              dirVal = "Bottom";
            } else {
              dirVal = "TopBottom";
            }
            $direction.val(dirVal);
            $n.val(filter.n);
          }

          $direction.on("change", function () {
            var dir = $(this).val();
            var dirVal;
            if (dir === "Top") {
              dirVal = window.morpheus.TopNFilter.TOP;
            } else if (dir === "Bottom") {
              dirVal = window.morpheus.TopNFilter.BOTTOM;
            } else {
              dirVal = window.morpheus.TopNFilter.TOP_BOTTOM;
            }
            filter.setDirection(dirVal);

            isColumns
              ? project.setColumnFilter(project.getColumnFilter(), true)
              : project.setRowFilter(project.getRowFilter(), true);
          });
          $n.on(
            "keyup",
            _.debounce(function (e) {
              filter.setN(parseInt($.trim($(this).val())));
              isColumns
                ? project.setColumnFilter(project.getColumnFilter(), true)
                : project.setRowFilter(project.getRowFilter(), true);
            }, 500)
          );

          return filter;
        };

        window.morpheus.FilterUI.prototype = {
          createFilter: function (options) {
            var $entry = options.$entry;
            var isColumns = this.isColumns;
            var filter = options.filter;
            var project = this.project;
            var fieldName = filter ? filter.name : options.fieldName;
            var $ui;
            if (!$entry) {
              var $add = $(this.add(filter));
              $add.data("filter", filter);
              $add.prependTo(this.$filters);
              $ui = $add.find("[data-name=ui]");
            } else {
              $ui = $entry.find("[data-name=ui]");
            }

            $ui.empty();
            var vector = (
              isColumns
                ? this.project.getFullDataset().getColumnMetadata()
                : this.project.getFullDataset().getRowMetadata()
            ).getByName(fieldName);

            if (filter instanceof window.morpheus.RangeFilter) {
              window.morpheus.FilterUI.rangeFilter(
                project,
                fieldName,
                isColumns,
                $ui,
                filter
              );
            } else if (filter instanceof window.morpheus.TopNFilter) {
              window.morpheus.FilterUI.topFilter(
                project,
                fieldName,
                isColumns,
                $ui,
                filter
              );
            } else if (
              filter == null &&
              window.morpheus.VectorUtil.isNumber(vector) &&
              window.morpheus.VectorUtil.containsMoreThanNValues(vector, 9)
            ) {
              filter = window.morpheus.FilterUI.rangeFilter(
                project,
                fieldName,
                isColumns,
                $ui,
                filter
              );
            } else {
              if (fieldName === "") {
                filter = new window.morpheus.AlwaysTrueFilter(isColumns);
              } else {
                var set = window.morpheus.VectorUtil.getSet(vector);
                var array = set.values();
                array.sort(window.morpheus.SortKey.ASCENDING_COMPARATOR);

                if (!filter) {
                  filter = new window.morpheus.VectorFilter(
                    new window.morpheus.Set(),
                    set.size(),
                    fieldName,
                    isColumns
                  );
                } else {
                  filter.maxSetSize = array.length;
                }

                var checkBoxList = new window.morpheus.CheckBoxList({
                  responsive: false,
                  $el: $ui,
                  items: array,
                  set: filter.set,
                });

                checkBoxList.on("checkBoxSelectionChanged", function () {
                  isColumns
                    ? project.setColumnFilter(project.getColumnFilter(), true)
                    : project.setRowFilter(project.getRowFilter(), true);
                });
              }
            }

            if (options.index != null) {
              (isColumns
                ? project.getColumnFilter()
                : project.getRowFilter()
              ).set(options.index, filter);
            }

            if ($entry) {
              $entry.data("filter", filter);
            }
            return filter;
          },

          addBase: function () {
            var html = [];
            var isColumns = this.isColumns;

            // var vector = (
            //   isColumns
            //     ? this.project.getFullDataset().getColumnMetadata()
            //     : this.project.getFullDataset().getRowMetadata()
            // ).getByName('mutation_summary')
            // var set = window.morpheus.VectorUtil.getSet(vector)
            // var array = set.values()
            // array.sort(window.morpheus.SortKey.ASCENDING_COMPARATOR)
            // array.length = 1
            // const combinedFilter = new window.morpheus.CombinedFilter(true)
            // const filterSet = new window.morpheus.Set()
            // filterSet.add(array)
            // const vectorFilter = new window.morpheus.VectorFilter(
            //   filterSet,
            //   fields.length,
            //   'mutation_summary',
            //   false
            // )
            // combinedFilter.add(vectorFilter)

            // heatMap.project.setRowFilter(combinedFilter, true)
            // heatMap.project.trigger('filterChanged')
            // console.log(heatMap, array)
            html.push(
              '<div style="padding-bottom:2px;border-bottom:1px solid #eee">'
            );
            html.push('<div class="row">');
            html.push(
              '<div class="col-xs-12">' +
                '<div class="checkbox"><label><input type="checkbox" name="filterMode">Pass all filters</label></div> ' +
                "</div>"
            );
            html.push("</div>");

            html.push('<div class="row">');
            html.push(
              '<div class="col-xs-8"><a class="btn btn-default btn-xs" style="margin-bottom:15px" role="button"' +
                ' data-name="add" href="#">Add</a></div>'
            );

            html.push("</div>");
            html.push("</div>");

            html.push(
              '<div class="form-group" style="margin-top:25px"><label>Variant Type Filter</label> ' +
                fields
                  .map(
                    (field, index) =>
                      `<div class="checkbox"><label><input type="checkbox"   onchange="handleVariantChange(event)" value="${index}" name="variant-filter"> ${field}</label></div>`
                  )
                  .join("") +
                "</div>"
            );
            html.push(`
              <div class="switch-isolated"><label id="isolated-text"> Not Isolated </label> <label class="switch"> <input type="checkbox"  onchange="handleIsolatedChange(event)"> <span class="slider"></span></label></div>
              `);
            return html.join("");
          },
          add: function (filter) {
            var project = this.project;
            var isColumns = this.isColumns;
            var fields = window.morpheus.MetadataUtil.getMetadataNames(
              isColumns
                ? project.getFullDataset().getColumnMetadata()
                : project.getFullDataset().getRowMetadata()
            );
            var html = [];
            html.push('<div class="morpheus-entry">');

            html.push('<div class="form-group">');
            html.push("<label>Fieldasassa</label>");
            html.push(
              '<select style="max-width:160px;overflow-x:hidden;" name="by" class="form-control input-sm">'
            );
            html.push('<option value=""></option>');
            var filterField = filter ? filter.toString() : null;

            _.each(fields, function (field) {
              html.push('<option value="' + field + '"');
              if (field === filterField) {
                html.push(" selected");
              }
              html.push(">");
              html.push(field);
              html.push("</option>");
            });
            html.push("</select>");
            html.push("</div>");
            html.push('<div class="row">');
            html.push('<div data-name="ui" class="col-xs-12"></div>');
            html.push("</div>");

            html.push(
              '<div style="padding-bottom:6px; border-bottom:1px solid #eee" class="row">'
            );
            html.push('<div class="col-xs-11">');
            html.push(
              '<a class="btn btn-default btn-xs" role="button" data-name="delete"' +
                ' href="#">Remove</a>'
            );
            html.push("</div>");
            html.push("</div>"); // row
            html.push("</div>"); // morpheus-entry
            return html.join("");
          },
        };

        const rowMetadata = dataset.getRowMetadata();
        const mutationSummaryIndex = rowMetadata.getByName("mutation_summary");
        const fields = mutationSummaryIndex
          .getProperties()
          .get("morpheus.fields");
        setFields(fields);

        window.handleVariantChange = (event) => {
          const { value, checked } = event.target;
          setSelectedVariants((prev) =>
            checked ? [...prev, value] : prev.filter((v) => v !== value)
          );
        };
        window.handleIsolatedChange = (event) => {
          const { checked } = event.target;
          setIsolated(checked);
          $("#isolated-text").text(checked ? "Isolated" : "Not Isolated");
        };
      });
    } catch (error) {
      console.error("Dataset oluşturulurken hata:", error);
    }
  }, []);

  const applyFilters = () => {
    if (selectedVariants.length > 0 && originalDataset) {
      var vector = heatMapRef.current.project
        .getFullDataset()
        .getRowMetadata()
        .getByName("mutation_summary");
      var set = window.morpheus.VectorUtil.getSet(vector);
      var array = set.values();
      array.sort(window.morpheus.SortKey.ASCENDING_COMPARATOR);

      var filteredArray = array.filter((item) => {
        if (isolated) {
          if (selectedVariants.length === 1) {
            // Seçili olan sadece bir eleman varsa, o elemanın indexi 0'dan büyük ve diğerleri 0 olmalı
            var selectedIndex = parseInt(selectedVariants[0]);
            return (
              item[selectedIndex] > 0 &&
              item.every(
                (value, index) => index === selectedIndex || value === 0
              )
            );
          } else if (selectedVariants.length > 1) {
            // Seçili olan birden fazla eleman varsa, seçilenlerin hepsi 0'dan büyük ve diğerleri 0 olmalı
            var allSelectedNonZero = selectedVariants.every(
              (index) => item[parseInt(index)] > 0
            );
            var allOthersZero = true;

            for (var i = 0; i < item.length; i++) {
              if (!selectedVariants.map(Number).includes(i) && item[i] !== 0) {
                allOthersZero = false;
                break;
              }
            }

            return allSelectedNonZero && allOthersZero;
          }
        } else {
          // isolated false ise, normal filtreleme işlemi
          return selectedVariants.some((index) => item[parseInt(index)] > 0);
        }
        return false;
      });

      const combinedFilter = new window.morpheus.CombinedFilter(true);
      if (filteredArray.length > 0) {
        const filterSet = new window.morpheus.Set();
        filteredArray.forEach((item) => filterSet.add(item));
        const vectorFilter = new window.morpheus.VectorFilter(
          filterSet,
          fields.length + 1000,
          "mutation_summary",
          false
        );
        combinedFilter.add(vectorFilter);
      } else {
        // Eğer filtrelenmiş veri yoksa, geçersiz bir set ekleyin
        const invalidSet = new window.morpheus.Set();
        invalidSet.add("INVALID_VALUE");
        const invalidFilter = new window.morpheus.VectorFilter(
          invalidSet,
          fields.length,
          "mutation_summary",
          false
        );
        combinedFilter.add(invalidFilter);
      }
      console.log(filteredArray);
      console.log(combinedFilter);
      heatMapRef.current.project.setRowFilter(combinedFilter, true);
      heatMapRef.current.project.trigger("filterChanged");
    } else {
      if (heatMapRef.current) {
        const combinedFilter = new window.morpheus.CombinedFilter(true);
        heatMapRef.current.project.setRowFilter(combinedFilter, true);
        heatMapRef.current.project.trigger("filterChanged");
      }
    }
  };

  useEffect(() => {
    applyFilters();
  }, [selectedVariants, isolated]);

  return (
    <>
      <div id="vis" style={{ width: "100%", height: "500px" }}></div>
    </>
  );
};

export default Heatmap;
