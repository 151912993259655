import React, { useState } from "react";

const TransferList = ({
  sampleList,
  left,
  right,
  setLeft,
  setRight,
  checked,
  setChecked,
}) => {
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const moveChecked = (from, to, setFrom, setTo) => {
    const newFrom = from.filter((item) => !checked.includes(item));
    const newTo = to.concat(from.filter((item) => checked.includes(item)));

    setFrom(newFrom);
    setTo(newTo);
    setChecked([]);
  };

  return (
    <div
      className="transfer-list"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div className="list-container" style={{ margin: "0 20px" }}>
        <h5 style={{ position: "absolute", top: "0" }}>
          <b>Available Samples</b>
        </h5>
        <List items={left} checked={checked} handleToggle={handleToggle} />
        {/* <div
          className="buttons-container"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
          }}
        >
          <div className="select-all">
            <button
              onClick={() => {
                setChecked(left);
              }}
            >
              Select All
            </button>
          </div>
          <div className="de-select-all">
            <button
              onClick={() => {
                setChecked([]);
              }}
            >
              Deselect All
            </button>
          </div>
        </div> */}
      </div>
      <div
        className="buttons-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <button onClick={() => moveChecked(left, right, setLeft, setRight)}>
          &gt;
        </button>
        <button onClick={() => moveChecked(right, left, setRight, setLeft)}>
          &lt;
        </button>
      </div>
      <div className="list-container" style={{ margin: "0 20px" }}>
        <h5 style={{ position: "absolute", top: "0" }}>
          <b>Selected Samples</b>
        </h5>
        <List items={right} checked={checked} handleToggle={handleToggle} />
      </div>
    </div>
  );
};

const List = ({ items, checked, handleToggle }) => (
  <ul className="list" style={{ maxHeight: "350px", overflowY: "auto" }}>
    {items.map((item, index) => (
      <ListItem
        key={index}
        value={item.label}
        checked={checked.includes(item)}
        handleToggle={handleToggle(item)}
      />
    ))}
  </ul>
);

const ListItem = ({ value, checked, handleToggle }) => (
  <li className="list-item">
    <input
      type="checkbox"
      checked={checked}
      onChange={handleToggle}
      style={{ marginRight: "8px", width: "auto" }}
    />
    {value}
  </li>
);

export default TransferList;
